<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7">*温馨提示：数据用于跨院注册并绑定健康卡，确认信息正确后再进行提交。</span>
      </template>
    </van-cell>   
    <van-list>
      <van-tabs v-model="active" type="card" color="#57c4b7">
  <van-tab title="手动输入">
          <van-cell-group >
  <van-field
    v-model="userName"
    required
    label="姓名"
    placeholder="请输入姓名"
  />
   <van-field
    v-model="idCard"
    required
    label="身份证号"
    placeholder="请输身份证号"
  />
  <van-field
    v-model="nation"
    required
    label="民族"
    placeholder="请输入民族"
  />
    <van-field
    v-model="Sex"
    required
    label="性别"
    placeholder="请输入性别"
  />
  <van-field
    v-model="phone"
    required
    label="手机号"
    placeholder="请输入手机号"
  />
  <van-field
    v-model="Birthday"
    required
    label="出生日期"
    placeholder="请输入出生日期"
  />
  <van-field
    v-model="Address"
    required
    label="家庭地址"
    placeholder="请输入家庭地址"
  />
  
</van-cell-group>
 <div style="font-size:12px;color:blue;text-align:right;padding:20px;text-decoration:underline"  @click="binding">已有健康卡，前往一键关联</div>
  <van-button  style="display:block;margin:15px auto;" color="#57c4b7" type="primary" @click="input">注册健康卡</van-button>
  </van-tab>
  <van-tab title="上传身份证照片">
     <div v-if="orcstate==0" style="text-align:center;padding:10px;color:blue">识别中...</div>
     <div v-else-if="orcstate==1" style="text-align:center;padding:10px;color:green">识别成功，填写电话号码完成注册</div>
     <div v-else-if="orcstate==-1" style="text-align:center;padding:10px;color:red">识别失败,请重新提交</div>
     <div v-else ></div>
    <van-uploader :disabled="orcstate==0"  :after-read="afterRead" style="margin-top:10px;">
      <template slot="default">
        <div style="width:80%;border:1px solid black;margin:0 auto;">
           <img v-if="src" :src="src" alt="" width="100%" height="180px" />
           <img v-else :src="idCardImg" alt="" width="100%" height="180px" />
        </div> 
        <div style="text-align:center">（点击上方图片可更换）</div>
      </template>
    </van-uploader>
    <div>
        <div  v-if="orcstate!==1" style="width:86%;margin:15px auto;padding-top:5px;border:1px solid red;font-size:13px">
          <h1 style="padding:5px;">上传提示：</h1>
          <p style="padding:5px;">1、请确保上传的证件照四个角完整。</p>
          <p style="padding:5px;">2、请确保照片清晰程度，过于模糊会影响ORC识别。</p>
          <p style="padding:5px;">3、尽量避免过远拍摄，如遇信息不对请重新上识别。</p>
        </div>
        <div v-else-if="orcstate==1" style="width:86%;margin:10px auto;;padding-top:5px;font-size:13px">
          <van-cell >
            <template slot="title">
              姓名: <span>{{ORCText.name}}</span>
            </template>
            <template slot="default">
              <div style="color:black">
                 性别: <span>{{ORCText.gender}}</span>
              </div>
            </template>
          </van-cell>
          <van-cell >
            <template slot="title">
               生日: <span>{{ORCText.birth}}</span>
              
            </template>
            <template slot="default">
              <div style="color:black">
               民族: <span>{{ORCText.nation}}</span>
              </div>
            </template>
          </van-cell>
          <van-cell >
            <template slot="default">
              身份证号码: <span>{{ORCText.id}}</span>
            </template>
          </van-cell>
          <van-cell >
            <template slot="default">
              家庭地址: <span>{{ORCText.address}}</span>
            </template>
          </van-cell>
          <van-field
              v-model="showPhone"
              required
              label="电话"
              placeholder="请输入电话号码"
              id="showPhone"
            />
            
        </div>
        <div style="font-size:12px;color:blue;text-align:right;padding-right:25px;text-decoration:underline"  @click="binding">已有健康卡，前往一键关联</div>
    </div>
    
      <van-button  :disabled="orcstate!==1" style="display:block;margin:15px auto;"  color="#57c4b7" type="primary" @click="input2">注册健康卡</van-button>
  </van-tab>
</van-tabs>
   
 <!-- <van-checkbox v-model="checked" checked-color="#57c4b7" shape="square" style="margin:8% 17%"> <a @click="onuseragree" style="color:#ABABAB">阅读并同意《用户协议》</a></van-checkbox> -->
    
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
        text-align:center;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import "@/assets/iconfont/write/iconfont.css";
import ajax from "../../lib/ajax";
import store from "../../store";
// import { Dialog } from 'vant';
import { Toast } from "vant";
const hospitalld="34742";
// import { Uploader } from 'vant';
export default {
  data() {
    return {
      checked: false,
      userName:"",
      phone:"",
      idCard:"",//身份证号
      nation:"",//民族
      qrCodeText:"",
      wechatCode:"",//微信授权码
      openid:"",
      userId: 0,
      showDialog:false,
      Sex: "",
      Birthday: "",
     card:{},//健康卡
     upData:"",//更新信息
     healthCardId:"",//健康卡id
     Address:"",//家庭地址
     active:"",
     src:"",
     idCardImg:require("../../assets/idCard.png"),
     orcstate:999,   //判断是否上传用
     showPhone:"",    //orc页用于显示的号码
     ORCText:{}       //识别后的数据
    };
  },
  created() {
    //  let openid = store.getters["wechat/openid"];
    //  console.log(openid)
    //  this.openid=openid;
    this.openid =  sessionStorage.getItem('openid');
     let wechatCode = this.$route.query.wechatCode;
     this.wechatCode=wechatCode;
    window.addEventListener("popstate", function() {
  console.log("a")
  this.$router.push("/healcardM");
 }, false);
     
  },
  methods: {
    //一键绑定按钮
    binding(){
     let redirect_uri="http://wx.fgxrmyy.cn/healcardM";
     window.location.href=`https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirect_uri}&hospitalId=${hospitalld}`;
    },
    //注册
    input(){
       
                let phoneReg=/1[3-9]\d{9}/;
                let idCardReg=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                 let BirthdayReg=/[0-9]{4}-[0-9]{2}-[0-9]{2}/;
              if(!this.userName){
                Toast.fail('姓名未填写');
                       return;
              }else if(!this.idCard){
                  Toast.fail('身份证号未填写');
                       return;
              }else if(!idCardReg.test(this.idCard)){
                  Toast.fail('身份证号格式错误');
              }
              else if(!this.nation){
                Toast.fail('民族未填写');
                       return;
              }else if(!this.Sex){
                  Toast.fail('性别填写未填写');
                       return;
              }
              else if(!this.phone){
                
                 Toast.fail('电话未填写');
                       return;
              }else if(!phoneReg.test(this.phone)){
                         Toast.fail('电话格式错误');
              }
              else if(!this.Birthday){
                   Toast.fail('出生日期未填写');
                       return;
              } else if(!BirthdayReg.test(this.Birthday)){
                   Toast.fail('出生日期格式错误：xxxx-xx-xx');
                       return;
              }else if(!this.Address){
                 Toast.fail('地址未填写');
                       return;
              }
              let subData={
                          birthday:  this.Birthday,
                          idType: "01",
                          gender: this.Sex,
                          idNumber: this.idCard,
                          name: this.userName,
                          nation: this.nation,
                          phone1: this.phone,
                          wechatCode:this.wechatCode,
                  }
                //  console.log(subData);
                //  console.log(this.appToken)
                ajax.post("/Api/HealthCardService/RegisterHealthCard",subData).then((res)=>{
                console.log(res)
                  this.healthCardId=res.data.result.rsp.healthCardId;
                  this.qrCodeText=res.data.result.rsp.qrCodeText;
                   if(res.data.result.commonOut.errMsg=="成功"){
                      this.bind();
                    //  store.commit('store/setQrCodeText',this.qrCodeText);
                    //  store.commit('store/setUserName',this.userName);
                    //  store.commit('store/setIdcard',this.idCard);
                    //  store.commit('store/setPhone',this.phone);
                    //  store.commit('store/setHealthCardId',this.healthCardId);
                    //  this.$router.push("/HcCode");
                   }else{
                      Toast.fail(res.data.result.commonOut.errMsg);
                   }
                  // userName
                  // idcard
                  // phone
                  // this.bind();
                
                }).catch((err)=>{
                  console.log(err);
                   Toast.fail('注册失败，请检查联系工作人员');
                })
    },
    input2(){
               
              let phoneReg=/1[3-9]\d{9}/;
              if(!this.showPhone){
                document.getElementById("showPhone").focus();
                 Toast.fail('电话未填写');
                       return;
              }else if(!phoneReg.test(this.showPhone)){
                         Toast.fail('电话格式错误');
                         document.getElementById("showPhone").focus();
                         return;
              }
              this.phone=this.showPhone;
              this.idCard=this.ORCText.id;
              console.log(this.ORCText.id)
              this.userName=this.ORCText.name;
              this.Sex=this.ORCText.gender;
              this.nation=this.ORCText.nation;
              this.Address=this.ORCText.address;
              let c = this.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let Birthday = year + "-" + month + "-" + day;
              this.Birthday= Birthday;
              let subData={
                          birthday:  this.Birthday,
                          idType: "01",
                          gender: this.Sex,
                          idNumber: this.idCard,
                          name: this.userName,
                          nation: this.nation,
                          phone1: this.phone,
                          wechatCode:this.wechatCode,
                  }
                //  console.log(subData);
                //  console.log(this.appToken)
                ajax.post("/Api/HealthCardService/RegisterHealthCard",subData).then((res)=>{
                console.log(res)
                  this.healthCardId=res.data.result.rsp.healthCardId;
                  this.qrCodeText=res.data.result.rsp.qrCodeText;
                   if(res.data.result.commonOut.errMsg=="成功"){
                      this.bind();
                    //  store.commit('store/setQrCodeText',this.qrCodeText);
                    //  store.commit('store/setUserName',this.userName);
                    //  store.commit('store/setIdcard',this.idCard);
                    //  store.commit('store/setPhone',this.phone);
                    //  store.commit('store/setHealthCardId',this.healthCardId);
                    //  this.$router.push("/HcCode");
                   }else{
                      Toast.fail(res.data.result.commonOut.errMsg);
                   }
                  // userName
                  // idcard
                  // phone
                  // this.bind();
                
                }).catch((err)=>{
                  console.log(err);
                   Toast.fail('注册失败，请检查联系工作人员');
                })
    },
    onuseragree(){
      this.$router.replace({
        path:'UA',
      })
    },
    //绑定微信健康卡
     bind(){
      
        // ajax.get(`/Api/HealthCardService/BindCardRelation?Patid=530125200201171512&QrCodeText=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23:0:D660E98498E03EB4C4A4FDFAAD1EBF92:5300A0069FWHX0002&AppToken=F44AF8513DEAB893A6447ECE90299CB5`)
       ajax.get(`/Api/HealthCardService/BindCardRelation?QrCodeText=${this.qrCodeText}&Patid=${this.idCard}`)
      .then((res)=>{
        console.log(res)
        console.log("开始绑定了");
        if(res.data.result.commonOut.errMsg=="成功"){
         console.log("我开始了");
              let sex =this.Sex
            let c = this.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let Birthday = year + "/" + month + "/" + day;
               let ownerIDCard =store.getters["store/owneridcard"];
            ajax.post("/Api/CardService/WeChatRegistOther", {
              openID:this.openid,
              barCode:this.healthCardId,
              phone:this.phone,
              sex:sex,
              birthday:Birthday,
              userName: this.userName,
              idCard:this.idCard,
              familyAddress:this.Address,
              ownerIDCard:ownerIDCard,
              machineName:"wechat",
              operatorId:'0733'
            }).then((res)=>{
              console.log(res);
              if(res.data.result>0){
              Toast("添加成功");
              this.$router.replace({
                path:'/healcard'
              }).catch(err => {
                    console.log(err);
                });
              }else{
                 Toast("添加失败，请刷新后重试"+res.data.result);
              }
            }).catch((err)=>{
              console.log(err);
              Toast("就诊卡添加错误，请核对后再进行添加");
            })
        }else{
          Toast.fail('绑定失败,请核对身份信息是否正确');
          
        
        }
    
      }).catch(()=>{
         Toast.fail('参数错误');
      })
    },
     afterRead(file) {
      // 此时可以自行将文件上传至服务器
      // console.log(file);
      this.orcstate=0;
      this.src=file.content;//window.URL.createObjectURL(file.file)
      // console.log(this.src)
      ajax.post("/Api/HealthCardService/OcrInfo",{imageContent:this.src})
      .then((res)=>{
        console.log(res);
        if(res.data.result.commonOut.errMsg=="成功"){
           this.orcstate=1;
           this.ORCText=res.data.result.rsp.cardInfo;
        }else{
           this.orcstate=-1;          //失败
        }
      })
      .catch((err)=>{
        console.log(err)
      })
    },
  },
  watch:{
    idCard(old){
      let idCardReg=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if(idCardReg.test(old)){
      let c = old;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let sex = c.substring(16, 17);
              this.Sex = sex % 2 > 0 ? "男" : "女";
              this.Birthday = year + "-" + month + "-" + day;
      }
    }
  },
}; 
</script>

<style>
</style>
